import Vue from 'vue'
import App from './App.vue'
import router from './router'
import HeyUI from 'heyui';
import "heyui/themes/index.less";
import * as echarts from 'echarts'
import axios from 'axios'
import VXETable from 'vxe-table'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import 'vxe-table/lib/style.css'
// import './assets/css/vxe-table-style.scss'
Vue.use(VXETable)
Vue.prototype.$axios = axios
Vue.prototype.$echarts = echarts;
Vue.prototype.url = 'https://wbb.weibb.work'
Vue.use(HeyUI);
Vue.config.productionTip = false
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
