<template>
    <dv-full-screen-container>
        <div class="screen">
            <!-- <div style="overflow: hidden;">122</div> -->
            <div class="bcblock">
                <div class="lefttabs">
                    <div class="tabssel" ref="orderSee" style="cursor:pointer" @click="orderSeeClick">工单可视</div>
                    <div class="tabsnotsel" ref="deviceSee"  style="cursor:pointer" @click="deviceSeeClick" >设备可视</div>
                    <div class="tabsnotsel">耗材</div>
                </div>
                
                <div class="titblock">
                    <span class="title">
                        维帮帮数据可视化中心
                    </span>
                    
                </div>
                <div class="righttabs">
                    <div class="tabsnotsel">巡检可视</div>
                    <div class="tabsnotsel">其他可视</div>
                    <div style="text-align:center;margin-left: 1vw">
                        <div style="color: #00E6FF;font-size: 1.2vw">{{ timeStr }}</div>
                        <div style="color: #fff">{{ dateStr }}</div>
                    </div>
                    
                    
                </div>
                <div style="display:flex">
                    <div class="leftchatbox">
                    <div class="orderrank">
                        <div class="orderranktit">{{nowMonth}}月工程师工单排名</div>
                        <dv-scroll-ranking-board style="width: 20vw;margin-left: 2vw;height:20vh" :config="config" />
                    </div>
                    <div class="ordertotal">
                        <div class="ordertotaltit">30天工单总量统计</div>
                        <div ref="maychat" style="width: 23vw;height: 30vh" id="maychat"></div>
                        <!-- <dv-charts ref="orderTotal" :option="option" id="container" style="margin-top: -2vh" /> -->
                    </div>
                    <div class="customorder">
                        <div class="customordertit" style="z-index: 999;position:relative"><Select style="color:#fff;" v-model="customId" :no-border="true" @change="customChange" :equal-width="false" keyName="id" titleName="name" :datas="customList" :autosize='true' :null-option="false" placeholder="请选择您的内容"></Select>{{nowMonth}}月工单统计</div>
                        <div ref="maychat" style="width: 23vw;height: 31vh;margin-top: -4.5vh;z-index: 1" id="customOrderChat"></div>
                        <!-- <dv-charts style="height: 23vh;margin-top: 2vh" id="customOrderChat" :option="option2" /> -->

                    </div>
                </div>
                <div v-if="orderShow" class="midchatbox">
                    <div class="midtop">
                        <div style="display: flex;justify-content: space-around;align-items:center">
                            <div class="totalorder">
                                <div class="taotaltitle">
                                    <div style="color:#fff">年工单总数</div>
                                    <div style="display:flex;align-items:center;font-size: 16px">
                                        <DatePicker @change="yearChange" type="year" v-model="yearValue" style="color: #fff;margin-right: 10px" :no-border="true"></DatePicker>
                                        <i style="color:#fff;font-size: 10px" class="h-icon-down"></i>
                                    </div>
                                </div>
                                <div style="display:flex;justify-content: space-around;">
                                    <div v-for="(item,index) in yeartotal" class="totalnum"  style="color:#fff" :key="index">
                                        {{ item }}
                                    </div>
                                </div>
                                <div class="contrast">
                                    <div style="color: #fff">去年 {{lastyeartotal}} 单  同比</div>
                                    <div style="color: #FF2923" v-if="yearIncrease > 0">+{{yearIncrease}}% ↑</div>
                                    <div style="color: #88FF61" v-else>-{{yearIncrease}}% ↓</div>
                                </div>
                            </div>
                            <dv-decoration-2 :reverse="true" style="width:3px;height:80px;" />
                            <div class="totalorder">
                                <div class="taotaltitle">
                                    <div style="color:#fff">月工单总数</div>
                                    <div style="display:flex;align-items:center;font-size: 16px">
                                        <DatePicker @change="changeMonth" type="month" v-model="monthValue" style="color: #fff;margin-right: 10px" :no-border="true"></DatePicker>
                                        <i style="color:#fff;font-size: 10px" class="h-icon-down"></i>
                                    </div>
                                </div>
                                <div style="display:flex;justify-content: space-around;">
                                    <div v-for="(item,index) in monthtotal" class="totalnum"  style="color:#fff" :key="index">
                                        {{ item }}
                                    </div>
                                </div>
                                <div class="contrast">
                                    <div style="color: #fff">去年<span v-if="monthValue">{{monthValue.split('-')[1]}}</span><span v-else>{{nowMonth}}</span>月 {{lastmonthtotal}} 单  同比</div>
                                    <div style="color: #FF2923" v-if="monthIncrease > 0">+{{monthIncrease}}% ↑</div>
                                    <div style="color: #88FF61"  v-else>+{{monthIncrease}}% ↓</div>
                                </div>
                            </div>
                            <dv-decoration-2 :reverse="true" style="width:3px;height:80px;" />
                            <div class="totalorder">
                                <div class="taotaltitle">
                                    <div style="color:#fff">日工单总数</div>
                                    <div style="display:flex;align-items:center;font-size: 16px">
                                        <DatePicker @change="changeDay" :format="format" type="date" v-model="dateValue" style="color: #fff;margin-right: 10px" :no-border="true"></DatePicker>
                                        <i style="color:#fff;font-size: 10px" class="h-icon-down"></i>
                                    </div>
                                </div>
                                <div style="display:flex;justify-content: space-around;">
                                    <div v-for="(item,index) in daytotal" class="totalnum"  style="color:#fff" :key="index">
                                        {{ item }}
                                    </div>
                                </div>
                                <div class="contrast">
                                    <div style="color: #fff">昨日 {{lastdaytotal}} 单  环比</div>
                                    <div style="color: #FF2923" v-if="dayIncrease > 0">+{{monthIncrease}}% ↑</div>
                                    <div style="color: #88FF61"  v-else>+{{dayIncrease}}% ↓</div>
                                </div>
                            </div>
                        </div>
                        <div style="color: #fff;font-size: 18px;display:flex;width: 90%;margin-left:5%;justify-content: space-between;margin-top: 10px"> 
                            <div>
                                本月工单状态汇总
                            </div>
                            <div>
                                本日工单状态汇总
                            </div>
                        </div>
                        <div class="midbottom">
                            <div style="display:flex;align-items:center;justify-content: space-between;padding-top: 3.5vh;width: 43vw">
                                <div class="midbottomleft">
                                    <div style="width:80%;display:flex;justify-content: space-around;margin-left: -2vw">
                                        <div style="color: #fff;text-align:center">
                                            <img src='../assets/img/yipaidanicon.png' style="width:2.5vw;height:3vw"/>
                                            <div style="font-size:1.2vw">{{orderStatusList.m_assign}}</div>
                                            <div style="font-size: 0.6vw">已派单</div>
                                        </div>
                                        <div style="color: #fff;text-align:center">
                                            <img src='../assets/img/yijiejueicon.png' style="width:2.5vw;height:3vw"/>
                                            <div style="font-size:1.2vw">{{orderStatusList.m_solve}}</div>
                                            <div style="font-size: 0.6vw">已解决</div>
                                        </div>
                                        <div style="color: #fff;text-align:center">
                                            <img src='../assets/img/yipingjiaicon.png' style="width:2.5vw;height:3vw"/>
                                            <div style="font-size:1.2vw">{{orderStatusList.m_comment}}</div>
                                            <div style="font-size: 0.6vw">已评价</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="midbottommid">
                                    <dv-decoration-9 style="width:8vw;height:8vw;">
                                    <div>工单</div>
                                    <div>状态</div>
                                    </dv-decoration-9>

                                    
                                </div>
                                <div class="midbottomright">
                                    <div style="width:110%;display:flex;justify-content: space-around;margin-left: -2vw">
                                        <div style="color: #fff;text-align:center">
                                            <img src='../assets/img/yipaidanicon.png' style="width:2.5vw;height:3vw"/>
                                            <div style="font-size:1.2vw">{{orderStatusList.d_assign}}</div>
                                            <div style="font-size: 0.6vw">已派单</div>
                                        </div>
                                        <div style="color: #fff;text-align:center">
                                            <img src='../assets/img/yijiejueicon.png' style="width:2.5vw;height:3vw"/>
                                            <div style="font-size:1.2vw">{{orderStatusList.d_solve}}</div>
                                            <div style="font-size: 0.6vw">已解决</div>
                                        </div>
                                        <div style="color: #fff;text-align:center">
                                            <img src='../assets/img/yipingjiaicon.png' style="width:2.5vw;height:3vw"/>
                                            <div style="font-size:1.2vw">{{orderStatusList.d_comment}}</div>
                                            <div style="font-size: 0.6vw">已评价</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="midbottombox">
                        <div>实时工单</div>
                        <dv-scroll-board :config="tableconfig" style="width:40vw;height:27vh;margin-left: 1.5vw;margin-top: 1vw" />

                    </div>
                </div>
                <div v-if="deviceShow" class="midchatbox">
                    <div class="midtop2">
                        <div style="display: flex;justify-content: space-around;align-items:center">

                        </div>
                        <div style="color: #fff;font-size: 18px;display:flex;width: 90%;margin-left:5%;justify-content: space-around;margin-top: 4vh"> 
                            <div>
                                当前客户数量
                            </div>
                            <div>
                                当前设备数量
                            </div>
                        </div>
                        <div class="midbottom2">
                            <div style="display:flex;align-items:center;justify-content: space-between;padding-top: 3.5vh;width: 43vw">
                                <div class="midbottomleft2">
                                    <div style="display:flex;justify-content: center;">
                                        <div v-for="(item,index) in customtotal" class="totalnum"  style="color:#fff" :key="index">
                                            {{ item }}
                                        </div>
                                    </div>
                                </div>

                                <div class="midbottomright2">
                                    <div style="display:flex;justify-content: space-between;width: 70%;margin-left:15%;margin-left:3vw">
                                        <div v-for="(item,index) in devicetotal" class="totalnum2"  style="color:#fff;padding-left:1vw;padding-right:1vw" :key="index">
                                            {{ item }}
                                        </div>
                                    </div>
                                    <div style="display:flex;justify-content:space-around;color: #fff;font-size: 0.7vw;margin-top:0.4vh;align-items:center">
                                        <div>
                                            禁用设备
                                            <span style="color:#FF2923;font-size: 0.8vw;font-weight: bold">{{customDeviceNumList.ttj}}</span>
                                        </div>
                                        <div>
                                            正常设备
                                            <span style="color:#2FBF00;font-size: 0.8vw;font-weight: bold">{{customDeviceNumList.ttn}}</span>
                                        </div>
                                        <div>
                                            维修设备
                                            <span style="color:#FFE323;font-size: 0.8vw;font-weight: bold">{{customDeviceNumList.ttw}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div v-if="false" class="midbottombox">
                        <div>实时工单</div>
                        <dv-scroll-board :config="tableconfig" style="width:40vw;height:27vh;margin-left: 1.5vw;margin-top: 1vw" />

                    </div>
                    <div  class="midbottombox2">
                        <div style="color: #fff"> 实时设备</div>
                        <!-- <dv-scroll-board :config="tableconfig2" style="width:40vw;height:27vh;margin-left: 1.5vw;margin-top: 1vw" /> -->
                        <!-- <Table :datas="realtimeDeviceList" :border="border" :checkbox="checkbox" :stripe="stripe" :loading="loading">
                            <TableItem align="center" title="序号" prop="$serial" v-if="serial"></TableItem>
                            <TableItem align="center" title="设备名称" prop="name"></TableItem>
                            <TableItem align="center" prop="model_number" title="设备型号"></TableItem>
                            <TableItem align="center" title="设备归属" prop="client_name"></TableItem>
                            <TableItem align="center" title="供应商" prop="supplier_name"></TableItem>
                            <TableItem align="center" title="录入时间" prop="created_at"></TableItem>
                            <TableItem align="center" title="当前状态" prop="wk_status"></TableItem>
                            <TableItem align="center" title="工程师" prop="engineer_name"></TableItem>
                            <div slot="empty">自定义提醒：暂时无数据</div>
                        </Table> -->
                            <vxe-table  v-infinite-scroll="onLoad" infinite-scroll-distance="20"	 style="height: 53vh;width:40vw;margin-left:1.5vw" border="none" align="center" :data="realtimeDeviceList">
                                <vxe-column type="seq" title="序号" width="60"></vxe-column>
                                <vxe-column field="name" title="设备名称"></vxe-column>
                                <vxe-column field="model_number" title="设备型号"></vxe-column>
                                <vxe-column field="client_name" title="设备归属"></vxe-column>
                                <vxe-column field="supplier_name" title="供应商"></vxe-column>
                                <vxe-column field="wk_status" title="当前状态"></vxe-column>
                                <vxe-column field="created_at" title="录入时间"></vxe-column>
                                <vxe-column field="engineer_name" title="工程师"></vxe-column>
                            </vxe-table>

                    </div>
                </div>
                <div style="display:flex">
                        <div class="leftchatbox">
                            <div class="diftype">
                                <div class="diftypetit">{{nowMonth}}月不同类型设备工单占比</div>
                                <!-- <dv-active-ring-chart :config="config3" style="height: 23vh;margin-top: -2vh"  /> -->
                                <div ref="maychat" style="width: 23vw;height: 17vh;margin-top: 1vh" id="difTypeDevice"></div>
                            </div>
                            <div class="orderProportion">
                                <div class="orderProportiontit">{{nowMonth}}月各工单占比</div>
                                <!-- <dv-charts id='orderProportion' :option="option4" style=";height: 23vh;" /> -->
                                <div style="width: 23vw;height: 26vh" id="orderProportion"></div>
                            </div>
                            <div class="ordercomment">
                                <div class="ordercommenttit">{{nowMonth}}月工单评价占比</div>
                                <dv-charts :option="option3" id="orderComment" style="height:23 vh;margin-top: -2vh"  />
                                
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
    </dv-full-screen-container>


</template>
<script>
import Charts from '@jiaminghi/charts'

export default{
    data(){
        return{
            orderStatusList: [],
            token: '',
            yeartotal: [],
            customtotal:[],
            devicetotal:[],
            lastyeartotal: 0,
            yearNum: 0,
            monthNum: 0,
            dayNum: 0,
            lastmonthtotal: 0,
            lastdaytotal: 0,
            monthtotal: [],
            page: 0,
            realtimeDeviceList:[],
            daytotal: [],
            yearIncrease: 0,
            monthIncrease: 0,
            dayIncrease: 0,
            chooseYear: new Date().getFullYear().toString(),
            chooseMonth: '',
            chooseDay: '',
            format: 'YYYY-MM-DD',
            yearValue: new Date().getFullYear().toString(),
            monthValue: '',
            custom: '',
            customList: [],
            orderShow: true,
            deviceShow: false,
            customId: 0,
            dateStr: '',
            timeStr: '',
            dateValue: '',
            nowMonth: '',
            customDeviceNumList: [],
            tableconfig2:{
                header: [ '设备名称', '设备型号' , '设备编号' , '设备归属', '供应商' ,'录入时间', '当前状态' ,'工程师'],
                data: [],
                headerBGC: '#052046',
                oddRowBGC: '#025084',
                align: ['left'],
                carousel: 'page',
                index: true,
                indexHeader: '序号',
                columnWidth: [150]
            },
            tableconfig:{
                header: [ '工单号', '归属单位' , '设备名称' , '设备ID', '报修时间' ,'工单状态', '工程师'],
                data: [],
                headerBGC: '#052046',
                oddRowBGC: '#025084',
                align: ['left'],
                carousel: 'page',
                index: true,
                indexHeader: '序号',
                columnWidth: [150]
            },
            config3:{
                data: [],
                radius: '65%'
            },
            config: {
                data: [],
                showValue: true,
                rowNum: 3
                
            },
            option4:{

            },
            option3:{

            },
            option: {
                legend: {
                    textStyle: {
                        fill: '#fff'
                    }
                },
                xAxis: {
                    data: [],
                    boundaryGap: false,
                    axisLine: {
                        style: {
                            stroke: '#fff'
                        }
                    },
                    axisLabel: {
                        style: {
                            fill: '#fff'
                        }
                    },
                    axisTick: {
                        show: false
                    }
                },
                yAxis: {
                    data: 'value',
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        style: {
                            stroke: '#fff'
                        }
                    },
                    axisLabel: {
                        style: {
                        fill: '#fff'
                        },
                        formatter ({ value }) {
                        return value.toFixed(2)
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    min: 95,
                    max: 100,
                    interval: 0.5
                },
                series: [
                    {
                        data: [],

                        type: 'line',
                        lineStyle: {
                            stroke: '#f5d94e'
                        },
                        lineArea: {
                            show: true,
                            gradient: ['rgba(246, 159, 0, 0.8)', 'rgba(246, 159, 0, 0.3)']
                        },
                        linePoint: {
                            radius: 6,
                            style: {
                                fill: '#F69F00'
                            }
                        }
                    }
                ]
            },
            option2:{
                xAxis: {
                    data: [
                        '1','2','3','4','5','6','7','8','9','10','11','12'
                    ],
                    boundaryGap: false,
                    axisLine: {
                        style: {
                            stroke: '#fff'
                        }
                    },
                    axisLabel: {
                        style: {
                            fill: '#fff'
                        }
                    },
                    axisTick: {
                        show: false
                    }
                },
                yAxis: {
                    data: 'value',
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        style: {
                            stroke: '#fff'
                        }
                    },
                    axisLabel: {
                        style: {
                        fill: '#fff'
                        },
                        formatter ({ value }) {
                        return value.toFixed(2)
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    min: 95,
                    max: 100,
                    interval: 0.5
                },
                series: [
                    {
                        data: [1200, 2230, 1900, 2100, 3500, 4200, 3985],
                        type: 'bar',
                        backgroundBar: {
                            show: true,
                            
                        },
                        gradient: {
                            color: ['#00F5F2', '#00F5F2']
                        }
                    }
                ]
            }
        }
    },
    mounted(){
        this.currentTime();
        this.token = this.$route.query.token || 'aacc'   
          
        let month = new Date().getMonth() + 1
        if(month < 10){
            this.monthValue = new Date().getFullYear().toString() + '0' + month.toString()
        }else{
            this.monthValue = new Date().getFullYear().toString() + month.toString()
        }
        let date = new Date().getDate()
        if(month >=10 && date>=10){
            this.dateValue =  new Date().getFullYear().toString()+ '-'   + month.toString() + '-'  + date.toString()
        }else if(month < 10 && date>=10){
            this.dateValue = new Date().getFullYear().toString() + '-'  + '0' + month.toString() + '-'    + date.toString()
        }else if(month > 10 && date <10){
            this.dateValue = new Date().getFullYear().toString() + '-'  + month.toString() + '-'  + '0' + date.toString()
        }else if( month < 10 && date < 10){
            this.dateValue = new Date().getFullYear().toString() + '-' + '0' + month.toString() + '-' + '0'+ date.toString()
        }
        setTimeout(()=>{
            this.getdifTypeChat()
        },1000)
        this.getEOrderRank()
        this.getOrderTotal()
        this.getCustomList()
        this.getTotalOrderNum()
        this.getOrderStatusTotal()
        this.getRealtimeOrder()
        this.getDifDeviceOrder()
        this.getOrderProportion()
        this.getOrderCommentProportion()
        
    },
    methods:{
        onLoad(){
            this.getRealtimeDevice()
        },
        orderSeeClick(){
            this.$refs.deviceSee.setAttribute('class','tabsnotsel')
            this.$refs.orderSee.setAttribute('class','tabssel')
            this.getRealtimeOrder()
            this.getTotalOrderNum()
            this.getOrderStatusTotal()
            this.orderShow = true
            this.deviceShow = false
        },
        deviceSeeClick(){
            this.$refs.deviceSee.setAttribute('class','tabssel')
            this.$refs.orderSee.setAttribute('class','tabsnotsel')
            this.getRealtimeDevice()
            this.getCustomDeviceNum()
            this.orderShow = false
            this.deviceShow = true
        },
        getCustomDeviceNum(){
            let nonce = Math.round(new Date() / 60000).toString(33)
            this.$axios.post(
                this.url + '/statsApi/totals',
                {
                    nonce: nonce,
                    token: this.token,
                }).then( res=>{                    
                    if(res.data.code == 0){
                        this.customDeviceNumList = res.data.data
                        this.customtotal = res.data.data.client.toString().split('')
                        this.devicetotal = res.data.data.device.toString().split('')
                    }
                })
        },
        getRealtimeDevice(){
            this.page += 1
            let nonce = Math.round(new Date() / 60000).toString(33)
            this.$axios.post(
                this.url + '/statsApi/deviceList',
                {
                    nonce: nonce,
                    token: this.token,
                    page: this.page,
                    page_size: 10
                }).then( res=>{                    
                    if(res.data.code == 0){
                        this.realtimeDeviceList =  this.realtimeDeviceList.concat(res.data.data.list)
                        // let arr = []
                        // let time = 0
                        // for(let i in res.data.data){
                        //     var carr= []
                        //     time += 1
                        //     carr.push(res.data.data.list[i].name)
                        //     carr.push(res.data.data.list[i].model_number)
                        //     carr.push(res.data.data.list[i].serial_number)
                        //     carr.push(res.data.data.list[i].client_name)
                        //     carr.push(res.data.data.list[i].supplier_name)
                        //     carr.push(res.data.data.list[i].created_at)
                        //     carr.push(res.data.data.list[i].wk_status)
                        //     carr.push(res.data.data.list[i].engineer_name)
                        //     arr.push(carr)
                        // }
                        // if(time == res.data.data.length){
                        //     const {tableconfig2} = this
                        //     tableconfig2.data = arr
                        //     this.tableconfig2 = { ...this.tableconfig2 }

                        // }
                    }
                })
        },
        getOrderCommentProportion(){
            let nonce = Math.round(new Date() / 60000).toString(33)
            this.$axios.post(
                this.url + '/statsApi/ratioByComment',
                {
                    nonce: nonce,
                    token: this.token,
                }).then( res=>{                    
                    if(res.data.code == 0){
                        const container = document.getElementById('orderComment')
                        document.getElementById('orderComment').innerHTML = ''
                        const myChart = new Charts(container)
                        let obj = {}
                        obj = res.data.data
                        let time = 0
                        let total = 0
                        let once = 0
                        for(let n in res.data.data){
                            once += 1
                            total += res.data.data[n].total
                        }
                        if(once == res.data.data.length ){
                            for(let i in obj){
                                time += 1
                                obj[i].value =( obj[i].total/ total) *100
                                obj[i].radius = i *20 + '%'
                            }
                        }                      
                        if(time == res.data.data.length){
                            let option = {
                                series: [
                                    {
                                        type: 'gauge',
                                        startAngle: -Math.PI / 2,
                                        endAngle: Math.PI * 1.5,
                                        arcLineWidth: 8,
                                        radius: '60%',
                                        data: obj,
                                        axisLabel: {
                                            show: false
                                        },
                                        axisTick: {
                                            show: false
                                        },
                                        pointer: {
                                            show: false
                                        },
                                        dataItemStyle: {
                                            lineCap: 'round'
                                        },
                                        backgroundArc: {
                                            show: false
                                        },
                                        details: {
                                            show: true,
                                            formatter: '{name}占比{value}%',
                                            position: 'start',
                                            offset: [-10, 0],
                                            style: {
                                                fill: '#fff',
                                                fontSize: 13,
                                                textAlign: 'right',
                                            }
                                        }
                                    }
                                ]
                            }
                            console.log(option)
                            myChart.setOption(option)
                        }
                    }
                })
        },
        getOrderProportion(){
            let nonce = Math.round(new Date() / 60000).toString(33)
            this.$axios.post(
                this.url + '/statsApi/ratioByStatus',
                {
                    nonce: nonce,
                    token: this.token,
                }).then( res=>{                    
                    if(res.data.code == 0){
                        const container = document.getElementById('orderProportion')
                        document.getElementById('orderProportion').innerHTML = ''
                        const myChart = new Charts(container)
                        let obj = {}
                        obj = res.data.data
                        let time = 0
                        for(let i in obj){
                            time += 1
                            obj[i].value = obj[i].total
                        }
                        
                        if(time == res.data.data.length){
                            let option = {
                                series: [
                                    {
                                        type: 'pie',
                                        name: 'total',
                                        data: obj,
                                        radius: ['80%', '70%'],
                                        insideLabel: {
                                            show: true
                                        }
                                    }
                                ]   
                            }
                            myChart.setOption(option)
                        }
                    }
                })
        },
        getDifDeviceOrder(){
            let nonce = Math.round(new Date() / 60000).toString(33)
            this.$axios.post(
                this.url + '/statsApi/ratioByCatagory',
                {
                    nonce: nonce,
                    token: this.token,
                }).then( res=>{               
                    if(res.data.code == 0){
                        var chartDom = document.getElementById('difTypeDevice');
                        const myChart = this.$echarts.init(chartDom)
                        var obj = res.data.data
                        let time = 0
                        let time2 = 0
                        let total = 0
                        for(let i in res.data.data){
                            time += 1
                            total += res.data.data[i].total
                            console.log(total)
                        }
                        if(time == res.data.data.length){
                            for(let n in res.data.data){
                                obj[n].value = obj[n].total / total *100
                                obj[n].name = obj[n].category_name
                                time2 += 1
                                console.log(obj)
                            }

                        }
                        if(time2 == res.data.data.length){
                            let option = {
                                color: [
                                    "#A0CE3A",
                                    "#31C5C0",
                                    "#1E9BD1",
                                    "#0F347B",
                                    "#585247",
                                    "#7F6AAD",
                                    "#009D85",
                                    "rgba(250,250,250,0.3)",
                                ],
                                grid: {
                                    bottom: 150,
                                    left: 100,
                                    right: "10%",
                                },
                                legend: {
                                    orient: "vertical",
                                    top: "middle",
                                    right: "10%",
                                    textStyle: {
                                        color: "#f2f2f2",
                                        fontSize: 12,
                                    },
                                    icon: "roundRect",
                                    data: obj,
                                },
                                series: [
                                    // 主要展示层的
                                    {
                                        center: ["20%", "50%"],
                                        type: "pie",
                                        label: {
                                            normal: {
                                                show: true,
                                                formatter: "{c}%",
                                                textStyle: {
                                                    fontSize: 12,
                                                },
                                                position: "outside",
                                            },
                                            emphasis: {
                                                show: true,
                                            },
                                        },
                                        labelLine: {
                                            normal: {
                                                show: true,
                                                length: 15,
                                            },
                                                emphasis: {
                                                show: true,
                                            },
                                        },
                                        name: "民警训练总量",
                                        data: obj,
                                    }
                                ],
                            };
                            myChart.setOption(option);
                        }
                    }
                })
        },
        getRealtimeOrder(){
            let nonce = Math.round(new Date() / 60000).toString(33)
            this.$axios.post(
                this.url + '/statsApi/ordList',
                {
                    nonce: nonce,
                    token: this.token,
                    page: 1,
                    page_size: 30
                }).then( res=>{                    
                    if(res.data.code == 0){
                        let arr = []
                        let time = 0
                        for(let i in res.data.data){
                            var carr= []
                            time += 1
                            carr.push(res.data.data[i].ord_sn)
                            carr.push(res.data.data[i].client_name)
                            carr.push(res.data.data[i].device_name)
                            carr.push(res.data.data[i].device_nid)
                            carr.push(res.data.data[i].created_at)
                            carr.push(res.data.data[i].status_text)
                            carr.push(res.data.data[i].engineer_name)
                            arr.push(carr)
                        }
                        if(time == res.data.data.length){
                            const {tableconfig} = this
                            tableconfig.data = arr
                            this.tableconfig = { ...this.tableconfig }
                        }
                    }
                })
        },
        getOrderStatusTotal(){
            let nonce = Math.round(new Date() / 60000).toString(33)
            this.$axios.post(
                this.url + '/statsApi/ordStatus',
                {
                    nonce: nonce,
                    token: this.token,
                }).then( res=>{
                    if(res.data.code == 0){
                        this.orderStatusList = res.data.data
                    }
                })
        },
        yearChange(e){
            let nonce = Math.round(new Date() / 60000).toString(33)
            this.$axios.post(
                this.url + '/statsApi/totalByYmd',
                {
                    nonce: nonce,
                    token: this.token,
                    ymd: e
                }).then( res=>{
                    if(res.data.code == 0){
                        let yeartotal = res.data.data[e]
                        this.yearNum = yeartotal
                        this.yeartotal = yeartotal.toString().split('')
                        this.lastyeartotal =  res.data.data[e -1]
                        console.log(yeartotal)
                        if(this.lastyeartotal == 0 && this.yearNum >0){
                            this.yearIncrease = 100
                        }else if(this.lastyeartotal > 0 && this.yearNum == 0){
                            this.yearIncerase = 0
                        }else if(this.lastyeartotal == 0 && this.yearNum == 0){
                            console.log(yeartotal)
                            this.yearIncrease = 0
                        }else if(this.lastyeartotal > 0 && this.yearNum > 0){
                          this.yearIncrease =  (this.yearNum - this.lastyeartotal) / this.lastyeartotal * 100
                        }
                        
                    }
                })
        },
        changeMonth(e){  
            let month = e.split('-')[0] + e.split('-')[1]
            console.log(month)
            let nonce = Math.round(new Date() / 60000).toString(33)
            this.$axios.post(
                this.url + '/statsApi/totalByYmd',
                {
                    nonce: nonce,
                    token: this.token,
                    ymd: month
                }).then( res=>{
                    if(res.data.code == 0){
                        let monthtotal = res.data.data[month]
                        this.monthNum = monthtotal
                        this.monthtotal = monthtotal.toString().split('')
                        
                        this.lastmonthtotal = res.data.data[parseInt(month) -100 ]
                        
                        if(this.lastmonthtotal == 0 && this.monthNum >0){
                            this.monthIncrease = 100
                        }else if(this.lastmonthtotal > 0 && this.monthNum == 0){
                            this.monthIncrease = 0
                        }else if(this.lastmonthtotal == 0 && this.monthNum == 0){
                            this.monthIncrease = 0
                        }else if(this.lastmonthtotal > 0 && this.monthNum > 0){
                          this.monthIncrease =  (this.monthNum - this.lastmonthtotal) / this.lastmonthtotal * 100
                        }
                    }
                })
        },
        changeDay(e){
            console.log(e)
            let day = e.split('-')[0] + e.split('-')[1] +  e.split('-')[2]
            console.log(month)
            let nonce = Math.round(new Date() / 60000).toString(33)
            this.$axios.post(
                this.url + '/statsApi/totalByYmd',
                {
                    nonce: nonce,
                    token: this.token,
                    ymd: day
                }).then( res=>{
                    if(res.data.code == 0){
                        let daytotal = res.data.data[day]
                        this.dayNum = daytotal
                        this.daytotal = daytotal.toString().split('')
                        this.lastdaytotal = res.data.data[parseInt(day) -1 ]

                        if(this.lastdaytotal == 0 && this.dayNum >0){
                            this.dayIncrease = 100
                        }else if(this.lastdaytotal > 0 && this.dayNum == 0){
                            this.dayIncrease = 0
                        }else if(this.lastdaytotal == 0 && this.dayNum == 0){
                            this.dayIncrease = 0
                        }else if(this.lastdaytotal > 0 && this.dayNum > 0){
                          this.dayIncrease =  (this.dayNum - this.lastdaytotal) / this.lastdaytotal * 100
                        }
                    }
                })
        },
        getTotalOrderNum(){
            let nonce = Math.round(new Date() / 60000).toString(33)
            this.$axios.post(
                this.url + '/statsApi/totalData',
                {
                    nonce: nonce,
                    token:this.token
                }).then( res=>{
                    if(res.data.code == 0){
                        let yeartotal = res.data.data.year[new Date().getFullYear()]
                        this.yearNum = yeartotal
                        let yy = new Date().getFullYear();
                        let mm = new Date().getMonth() + 1 < 10 ? '0' +(new Date().getMonth() + 1) :new Date().getMonth() + 1;
                        let dd = new Date().getDate();
                        let chooseMonth = yy.toString()+ mm.toString() 
                        let chooseDay = yy.toString()+ mm.toString() + dd.toString()
                        let nowMonth = mm
                        let monthtotal = res.data.data.month[chooseMonth]

                        this.monthNum = monthtotal
                        let daytotal = res.data.data.day[chooseDay]
                        this.datNum = daytotal
                        this.yeartotal = yeartotal.toString().split('')
                        this.monthtotal = monthtotal.toString().split('')
                        this.daytotal = daytotal.toString().split('')
                        this.lastyeartotal =  res.data.data.year[new Date().getFullYear() -1]
                        this.lastmonthtotal = res.data.data.month[new Date().getFullYear() -1 + nowMonth ]
                        this.lastdaytotal = res.data.data.day[parseInt(chooseDay) -1]
                        if(this.lastyeartotal == 0 && this.yearNum >0){
                            this.yearIncrease = 100
                        }else if(this.lastyeartotal > 0 && this.yearNum == 0){
                            this.yearIncerase = 0
                        }else if(this.lastyeartotal == 0 && this.yearNum == 0){
                            this.yearIncerase = 0
                        }else if(this.lastyeartotal > 0 && this.yearNum > 0){
                          this.yearIncrease =  (this.yearNum - this.lastyeartotal) / this.lastyeartotal * 100
                        }
                        if(this.lastmonthtotal == 0 && this.monthNum >0){
                            this.monthIncrease = 100
                        }else if(this.lastmonthtotal > 0 && this.monthNum == 0){
                            this.monthIncrease = 0
                        }else if(this.lastmonthtotal == 0 && this.monthNum == 0){
                            this.monthIncrease = 0
                        }else if(this.lastmonthtotal > 0 && this.monthNum > 0){
                          this.monthIncrease =  (this.monthNum - this.lastmonthtotal) / this.lastmonthtotal * 100
                        }
                        if(this.lastdaytotal == 0 && this.dayNum >0){
                            this.dayIncrease = 100
                        }else if(this.lastdaytotal > 0 && this.dayNum == 0){
                            this.dayIncrease = 0
                        }else if(this.lastdaytotal == 0 && this.dayNum == 0){
                            this.dayIncrease = 0
                        }else if(this.lastdaytotal > 0 && this.dayNum > 0){
                          this.dayIncrease =  (this.dayNum - this.lastdaytotal) / this.lastdaytotal * 100
                        }
                    }
                })
        },
        customChange(e){
            console.log(e)
            this.custom = e.name
            this.customId = e.id
            this.getCustomData()
        },
        getCustomList(){
            let nonce = Math.round(new Date() / 60000).toString(33)
            this.$axios.post(
                this.url + '/statsApi/clientList',
                {
                    nonce: nonce,
                    token: this.token
                }).then( res=>{
                    console.log(res)
                    if(res.data.code == 0){
                        if(res.data.data.legnth != 0){
                            this.customList = res.data.data
                            this.custom = res.data.data[0].name
                            this.customId= res.data.data[0].id
                            this.getCustomData()
                        }
                    }
                })
        },
        getCustomData(){
            let nonce = Math.round(new Date() / 60000).toString(33)
            this.$axios.post(
                this.url + '/statsApi/clientOrders',
                {
                    nonce: nonce,
                    token: this.token,
                    client_id: this.customId
                }).then( res=>{
                    if(res.data.code == 0){
                        var chartDom = document.getElementById('customOrderChat');
                        const myChart = this.$echarts.init(chartDom)
                        let timearr= []
                        let numarr = []
                        let time = 0
                        for(let n in res.data.data){
                            time += 1
                            numarr.push(res.data.data[n].total)
                            timearr.push(res.data.data[n].date)
                            
                        }
                        if(time == res.data.data.length){
                            let option = {
                                xAxis: {
                                    type: 'category',
                                    color: '#fff',
                                    tooltip: {
                                    show: true
                                    },
                                    data: timearr
                                },
                                yAxis: {
                                    type: 'value',
                                    tooltip: {
                                    show: true
                                    }
                                },
                                textStyle:{
                                    color: '#fff'
                                },
                                tooltip: {},
                                series: [
                                    {
                                        data: numarr,
                                        type: 'bar',
                                        showBackground: true,
                                        zIndex:1,
                                        backgroundStyle: {
                                            color: 'rgba(180, 180, 180, 0.2)'
                                        },
                                        itemStyle:{
                                            normal:{
                                                color: '#00F5F2'
                                            }
                                        }
                                    }
                                ]
                            };
                            myChart.setOption(option);
                        }
                        
                        
                    }
                })
        },
        getOrderTotal(){
            let nonce = Math.round(new Date() / 60000).toString(33)
            this.$axios.post(
                this.url + '/statsApi/ordersByDay',
                {
                    nonce: nonce,
                    token: this.token
                }).then( res=>{
                    if(res.data.code == 0){
                        let timearr= []
                        let numarr = []
                        let time = 0
                        for(let n in res.data.data){
                            time += 1
                            numarr.push(res.data.data[n].total)
                            timearr.push(res.data.data[n].date)
                        }
                        if(time == res.data.data.length){
                            var chartDom = document.getElementById('maychat');
                            var myChart = this.$echarts.init(chartDom);
                            let option = {
                                xAxis: {
                                    type: 'category',
                                    boundaryGap: false,
                                    data: timearr
                                },
                                yAxis: {
                                    type: 'value'
                                },
                                tooltip: {},
                                series: [
                                    {
                                        data: numarr,
                                        type: 'line',
                                        areaStyle: {},
                                        color: '#F69F00',
                                        
                                    }
                                ],
                                textStyle:{
                                    color: '#fff'
                                },
                                lineStyle:{
                                    color: '#F69F00'
                                },
                                areaStyle:{
                                    color: '#F69F00'
                                },
                                
                            };
                            myChart.setOption(option)
                        }
                    }
                })
        },
        getEOrderRank(){
            let nonce = Math.round(new Date() / 60000).toString(33)
            this.$axios.post(
                this.url + '/statsApi/eerOrders',
                {
                    nonce: nonce,
                    token: this.token
                }).then( res=>{
                    console.log(res)
                    if(res.data.code == 0){
                        for(let i in res.data.data){
                            let obj =  {
                                name: res.data.data[i].name,
                                value: res.data.data[i].total
                            }
                            this.config.data.push(obj)
                            const { config } = this
                            this.config = { ...this.config }
                        }
                    }
                })
        },
        currentTime() {
            setInterval(this.getTime, 500);
        },
        getTime() {
            let arr=['日','一','二','三','四','五','六']
            let yy = new Date().getFullYear();
            let mm = new Date().getMonth() + 1 < 10 ? '0' +(new Date().getMonth() + 1) :new Date().getMonth() + 1;
            let dd = new Date().getDate();
            let hh = new Date().getHours();
            let mf =
                new Date().getMinutes() < 10
                ? "0" + new Date().getMinutes()
                : new Date().getMinutes();
            let ss =
                new Date().getSeconds() < 10
                ? "0" + new Date().getSeconds()
                : new Date().getSeconds();
            let day = new Date().getDay() 
            this.dateStr =
                yy + "/" + mm + "/" + dd + " " +' ' + '星期'+ arr[day]
            this.timeStr =  hh + ":" + mf + ":" + ss;
            this.nowMonth = mm
            this.chooseMonth  = yy.toString()+ mm.toString() 
            this.chooseDay = yy.toString()+ mm.toString() + dd.toString()
        },
        getdifTypeChat(){
            console.log(this.$refs)
            // let myChart = this.$echarts.init(this.$refs.diftypechat)
            
            let option = {
                legend: {
                    orient: 'vertical',
                    right: 'right'
                },
                toolbox: {
                    show: false,
                    feature: {
                    mark: { show: true },
                    dataView: { show: true, readOnly: false },
                    restore: { show: true },
                    saveAsImage: { show: true }
                    }
                },
                series: [
                    {
                        type: 'pie',
                        radius: [20, 80],
                        center: ['35%', '60%'],
                        roseType: 'area',
                        itemStyle: {
                            borderRadius: 8
                        },
                        data: [
                            { value: 40, name: 'rose 1' },
                            { value: 38, name: 'rose 2' },
                            { value: 32, name: 'rose 3' },
                            { value: 30, name: 'rose 4' },
                            { value: 28, name: 'rose 5' },
                            { value: 26, name: 'rose 6' },
                            { value: 22, name: 'rose 7' },
                        ]
                    }
                ]
            };
            // myChart.setOption(option)
        }
    }

}
</script>

<style>
.screen{
    background: url('../assets/img/bcbg.png') 100% 100%;
    /* padding-top: 30px; */
    /* padding: 30px */
}
.bcblock{
    background: url('../assets//img/bgblock.png') no-repeat;
    background-size: 98% 90% ;
    width: 100vw;
    height: 100vh;
    background-position:  center    90%;
    
}
.titblock{
    background: url('../assets/img/titbg.png') no-repeat;
    background-size: 60% 100%;
    background-position:  center 30%;
    /* padding-top: 60px; */
    font-size: 56px;
    margin-top: -65px;
    text-align:center;
}
.title{
    text-shadow: 0px 6px 4px rgba(14,29,51,0.5);

    color: #FFFFFF;
    background: linear-gradient(0deg, #4FBFF9 0%, #F4FBFF 35%);
    font-weight: 400;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
   
}
.tabssel{
    width: 163px;
    height: 53px;
    border: NaNpx solid;
    line-height: 53px;
    background: url('../assets/img/tabssel.png') no-repeat;
    background-size: 100% 100%;
    border-image: linear-gradient(0deg, #003E6A, #B5C6FF) 10 10;
    /* background: linear-gradient(0deg, #2B4884, #051F45); */
    font-size: 22px;
    font-family: ShiShangZhongHeiJianTi;
    font-weight: 400;
    color: #FFFFFF;
    text-align:center
}
.tabsnotsel{
    width: 163px;
    height: 53px;
    border: NaNpx solid;
    line-height: 53px;
    background: url('../assets/img/tabsnotsel.png') no-repeat;
    background-size: 100% 100%;
    border-image: linear-gradient(0deg, #003E6A, #B5C6FF) 10 10;
    /* background: linear-gradient(0deg, #2B4884, #051F45); */
    font-size: 22px;
    font-family: ShiShangZhongHeiJianTi;
    font-weight: 400;
    color: #FFFFFF;
    text-align:center;
}
.lefttabs{
    display:flex;
    margin-left: 56px;
    padding-top: 12px
}
.righttabs{
    display: flex;
    position:absolute;
    top: 12px;
    right: 56px
}
.orderrank{
    background: url('../assets/img/orderrankbg.png') no-repeat;
    background-size: 100% 100%;
    width: 23vw;
    height: 27vh;
    margin-left: 3.5vw
}
.diftype{
    background: url('../assets/img/diftypebg.png') no-repeat;
    background-size: 100% 100%;
    width: 23vw;
    height: 27vh;
    margin-left: 1.8vw
}
.ordertotal{
    background: url('../assets/img/ordertotalbg.png') no-repeat;
    background-size: 100% 100%;
    width: 23vw;
    height: 30vh;
    margin-left: 3.5vw;
    margin-top: 1vh
}
.orderProportion{
    background: url('../assets/img/orderproportionbg.png') no-repeat;
    background-size: 100% 100%;
    width: 23vw;
    height: 30vh;
    margin-left: 1.8vw;
    margin-top: 1vh
}
.customorder{
    background: url('../assets/img/ordertotalbg.png') no-repeat;
    background-size: 100% 100%;
    width: 23vw;
    height: 26vh;
    margin-left: 3.5vw;
    margin-top: 1vh
}
.ordercomment{
    background: url('../assets/img/ordercommentbg.png') no-repeat;
    background-size: 100% 100%;
    width: 23vw;
    height: 26vh;
    margin-left: 1.8vw;
    margin-top: 1vh
}
.midtop{
    background: url('../assets/img/midtopbg.png') no-repeat;
    background-size: 100% 100%;
    width: 43vw;
    height: 46vh;
}
.midtop2{
    background: url('../assets/img/midtopbg2.png') no-repeat;
    background-size: 100% 100%;
    width: 43vw;
    height: 28vh;
}

.orderranktit{
    font-size: 18px;
    font-weight: 400;
    color: #FEFEFF;
    text-align:right;
    width: 100%;
    margin-left: -2vw;
    padding-top: 4.2vh
    /* margin-left: 19vw; */
    /* padding-top: 4.2vh */
}
.diftypetit{
    font-size: 18px;
    font-weight: 400;
    color: #FEFEFF;
    text-align:left;
    width: 100%;
    margin-left: 3.5vw;
    padding-top: 4.4vh
    /* margin-left: 19vw; */
    /* padding-top: 4.2vh */
}
.ordertotaltit{
    font-size: 18px;
    font-weight: 400;
    color: #FEFEFF;
    text-align:right;
    width: 100%;
    margin-left: -2vw;
    padding-top: 1.6vh
}
.orderProportiontit{
    font-size: 18px;
    font-weight: 400;
    color: #FEFEFF;
    text-align:left;
    width: 100%;
    margin-left: 2.5vw;
    padding-top: 1.6vh
}
.customordertit{
    font-size: 18px;
    font-weight: 400;
    color: #FEFEFF;
    text-align:right;
    width: 100%;
    margin-left: -2vw;
    padding-top: 1.2vh
}
.ordercommenttit{
    font-size: 18px;
    font-weight: 400;
    color: #FEFEFF;
    text-align:left;
    width: 100%;
    margin-left: 3.5vw;
    padding-top: 1.2vh
}
.midchatbox{
    margin-top:30px;
    margin-left: 1.95vw
}
.midchatbox2{
    margin-top:30px;
    margin-left: 1.95vw
}
.totalnum{
    background: url('../assets/img/totalnumbg.png') no-repeat;
    background-size: 100% 100%;
    width: 2.5vw;
    height: 8vh;
    font-size: 50px;
    display:flex;
    align-items:center;
    justify-content: center

}
.totalnum2{
    background: url('../assets/img/totalnumbg.png') no-repeat;
    background-size: 100% 100%;
    width: 2.2vw;
    height: 8vh;
    font-size: 2vw;
    display:flex;
    align-items:center;
    justify-content: center

}
.totalorder{
    width: 12vw;
    padding-top: 5vh
}

.el-input__inner{
    border: none!important;
    background: none!important;
    border: 1px solid red;
    color: #fff!important;
    font-size: 16px;
    width: 80px
}
.taotaltitle{
    display:flex;
    margin-left: 2.5%;
    justify-content: space-between;
    width: 95%
}
.contrast{
    background: url('../assets/img/contrastbg.png') no-repeat;
    background-size: 100% 100%;
    display:flex;
    margin-top: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    justify-content: center;
    font-size: 16px
}
.midbottom{
    /* background: url('../assets/img/orderstatusbg.png') no-repeat; */
    background-size: 60% 100%;
    background-position: center 90%;
    width: 43vw;
    height: 20vh;
    
}
.midbottom2{
    background: url('../assets/img/orderstatusbg2.png') no-repeat;
    background-size: 50% 100%;
    background-position: center;
    width: 43vw;
    height: 20vh;
    
}
.midbottombox{
    background: url('../assets/img/realtimebg.png') no-repeat;
    background-size: 100% 100%;
    background-position: center 90%;
    width: 43vw;
    height: 35vh;
    margin-top: 1vh;
    text-align: center;
    color: #fff;
    font-size: 0.8vw;
    font-weight: bold
}
.midbottombox2{
    background: url('../assets/img/realtimeDevicebg.png') no-repeat;
    background-size: 100% 100%;
    background-position: center 90%;
    width: 43vw;
    height: 53vh;
    margin-top: 1vh;
    text-align: center;
    /* color: #fff; */
    font-size: 0.8vw;
    font-weight: bold;
    overflow-y: scroll
}
.midbottomleft{
    background: url('../assets/img/monthstatusbg.png') no-repeat;
    background-size: 100% 100%;
    width: 17vw;
    height: 14vh;
    margin-left: 1vw;
    display:flex;
    align-items: center;
    justify-content: center
}
.midbottomleft2{
    background: url('../assets/img/customnumbg.png') no-repeat;
    background-size: 100% 100%;
    width: 16vw;
    height: 14vh;
    margin-left: 1vw;
    display:flex;
    align-items: center;
    justify-content: center
}
.midbottomright{
    background: url('../assets/img/daystatusbg.png') no-repeat;
    background-size: 100% 100%;
    width: 17vw;
    height: 14vh;
    padding-left: 4vw;
    margin-right: 1vw;
    display:flex;
    align-items: center;
    justify-content: center
}
.midbottomright2{
    background: url('../assets/img/devicenumbg.png') no-repeat;
    background-size: 100% 100%;
    width: 16vw;
    height: 14vh;
    margin-right: 1vw;
    padding-top: 3vh;
    /* display:flex; */
    align-items: center;
    justify-content: center
}
.midbottommid{
    color: #fff;
    text-align: center;
    font-size: 16px;
    margin-left: 0.5vw;
    margin-top: -2vh;
    font-weight: 900
}
.vxe-table--header-wrapper,.vxe-header--row,.vxe-table--header{
    background: #012148;
    color: #fff
}
.vxe-body--row{
    background: #025084;
    color: #fff
}
.vxe-table--header-wrapper body--wrapper{
    border: none
}
.vxe-table--render-default .vxe-table--body-wrapper, .vxe-table--render-default .vxe-table--footer-wrapper{
    background-color: #025084!important
}
.vxe-table--render-default.border--inner .vxe-table--header-wrapper, .vxe-table--render-default.border--none .vxe-table--header-wrapper{
    background-color: #012148!important
}
</style>